import React from "react";
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiToolTip } from "@elastic/eui";

import { BorderColor, PageContentHeight } from "@pm-frontend/styles";
import { ApplicationSidebarBase, ApplicationSidebarBaseProps } from "./ApplicationSidebarBase";
import URL from "@pm-shared/utils/url";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { ApplicationSidebarMobile } from "./ApplicationSidebarMobile";
import { create } from "zustand";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";

const SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY = "ui_redesign_is_sidebar_nav_is_open";

const readSidebarStateFromLocalStorage = (user_key: string): "open" | "closed" => {
  const lsKey = SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY + user_key;
  const value = window.localStorage.getItem(lsKey);
  return value === "true" || value === null ? "open" : "closed";
};

const writeSidebarStateToLocalStorage = (user_key: string, state: boolean) => {
  const lsKey = SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY + user_key;
  window.localStorage.setItem(lsKey, state.toString());
};

interface ApplicationSidebarState {
  sidebarNavIsOpen: boolean;
  actions: {
    toggleSidebarNav: () => void;
  };
}

const SIDEBAR_STATE_LOCAL_STORAGE_KEY = `${AuthUtils.getActiveOrgType()}-${AuthUtils.getUserId()}`;

// corresponds to the default 's' EuiBreakpoint
const isMobileScreen = () => window.matchMedia("(max-width: 575px)").matches;

const useApplicationSidebarState = create<ApplicationSidebarState>((set) => {
  const sidebarNavIsOpen = isMobileScreen()
    ? false
    : readSidebarStateFromLocalStorage(SIDEBAR_STATE_LOCAL_STORAGE_KEY) === "open";

  const toggleSidebarNav = () => {
    set((state) => {
      // no need to persist this state on mobile
      if (!isMobileScreen()) {
        writeSidebarStateToLocalStorage(SIDEBAR_STATE_LOCAL_STORAGE_KEY, !state.sidebarNavIsOpen);
      }
      return { ...state, sidebarNavIsOpen: !state.sidebarNavIsOpen };
    });
  };
  return {
    sidebarNavIsOpen,
    actions: {
      toggleSidebarNav,
    },
  };
});

const ApplicationSidebar = (props: ApplicationSidebarBaseProps) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    if (!props.isOpen) {
      return null;
    }
    return <ApplicationSidebarMobile {...props} />;
  }

  // desktop collapsed icon sidebar view
  if (!props.isOpen) {
    return (
      <div style={{ padding: "64px 16px", boxShadow: `-1px 0px 0px 0px ${BorderColor} inset` }} className="eui-yScroll">
        <ApplicationSidebarBase {...props} />
        <div style={{ minHeight: "32px", paddingTop: "12px" }}>
          <EuiToolTip content="Show navigation" position="right">
            <EuiButtonIcon
              iconType={URL.getStatic("icons/menu_close-1.svg")}
              iconSize="original"
              onClick={props.toggleOpen}
              aria-label="Open sidebar menu"
              style={{ width: "20px", height: "20px" }}
            />
          </EuiToolTip>
        </div>
      </div>
    );
  }

  // full desktop view
  return (
    <EuiFlexGroup
      direction="column"
      style={{
        padding: "32px 8px 0px 16px",
        gap: "32px",
        maxHeight: PageContentHeight,
        overflowY: "auto",
        scrollbarGutter: "stable",
        boxShadow: `-1px 0px 0px 0px ${BorderColor} inset`,
      }}
      className="eui-yScroll"
    >
      <ApplicationSidebarBase {...props} />
      {props.desktopItems && (
        <EuiFlexGroup direction="column" style={{ marginTop: "auto", marginBottom: "auto" }} gutterSize="s">
          {props.desktopItems.map((item, index, items) => (
            <React.Fragment key={index}>
              <EuiFlexItem grow={false}>{item}</EuiFlexItem>
              {index < items.length - 1 ? (
                <EuiFlexItem grow={false}>
                  <EuiHorizontalRule margin="none" style={{ backgroundColor: BorderColor }} />
                </EuiFlexItem>
              ) : null}
            </React.Fragment>
          ))}
        </EuiFlexGroup>
      )}
    </EuiFlexGroup>
  );
};

export { ApplicationSidebar, useApplicationSidebarState };
