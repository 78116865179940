var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
import { create } from "zustand";
import { addDays, startOfDay, subDays } from "date-fns";
import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
import { CALENDAR_URL_PARAM_KEYS, getCalendarOrMapState } from "./utils/hooks";
import { BaseSavedFiltersFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import {
  getDefaultMeldFilters,
  getMeldSavedFilterConfig,
  MeldFilterQueryKeys
} from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { MeldFilterConfigs } from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { BaseSelectableFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSelectableFilter/BaseSelectableFilterClass";
import { getParamsMatchingFilters } from "@pm-frontend/shared/components/FilterButtons/utils";
import { MeldManagerStatusLabels, MeldStatus, OpenStatuses } from "@pm-frontend/shared/types/meld";
import { useLocation } from "react-router-dom";
import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
const TIMEFRAME_LOCAL_STORAGE_KEY = `meld-calendar-timeframe-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY = `meld-calendar-hide-nearby-melds-m-${AuthUtils.getUserId()}`;
const HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY = `meld-calendar-hide-map-row-nearby-melds-m-${AuthUtils.getUserId()}`;
const TIMEFRAMES = {
  ONE_DAY: 1,
  THREE_DAY: 3,
  FIVE_DAY: 5
};
const setInitialSelectedTimeFrame = (timeframe) => {
  for (const entry of Object.entries(TIMEFRAMES)) {
    if (timeframe === entry[1]) {
      window.localStorage.setItem(TIMEFRAME_LOCAL_STORAGE_KEY, entry[0]);
      return;
    }
  }
};
const getInitialSelectedTimeFrame = () => {
  const value = window.localStorage.getItem(TIMEFRAME_LOCAL_STORAGE_KEY);
  if (!value) {
    return 1;
  }
  return TIMEFRAMES[value] || 1;
};
const getInitialHideNearbyMeldsState = (key) => {
  const value = window.localStorage.getItem(key);
  if (value === "true") {
    return true;
  }
  return false;
};
const setInitialHideNearbyMeldsState = (key, state) => {
  window.localStorage.setItem(key, state.toString());
};
const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const setInitialSelectedVendorsOrAgents = (state) => window.localStorage.setItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));
const getInitialSelectedVendorsOrAgents = () => {
  const value = window.localStorage.getItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);
  const oldUiValue = window.localStorage.getItem(`MaintenanceUserStore:state:${AuthUtils.getAccountKey()}`);
  let result = { agents: [], vendors: [] };
  if (value) {
    try {
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
        result = parsed;
      }
    } catch (e) {
    }
  } else if (oldUiValue) {
    const parsed = JSON.parse(oldUiValue);
    if (parsed && Array.isArray(parsed.selectedAgents)) {
      parsed.selectedAgents.forEach((agent) => {
        if (agent.id && Number(agent.id)) {
          result.agents.push(agent.id);
        }
      });
    }
  }
  return result;
};
const CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `calendar-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
const MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `map-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
function getInitialMeldFilterParams(type) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  try {
    const rawValue = window.localStorage.getItem(key);
    if (rawValue) {
      return new URLSearchParams(rawValue);
    }
  } catch (e) {
    return void 0;
  }
  return void 0;
}
function writeMeldFilterParamsToStorage(type, params) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  try {
    window.localStorage.setItem(key, params.toString());
  } catch (e) {
    return;
  }
}
const useCalendarStateStore = create((set, get) => {
  const onApplyAdditionalOnClick = (newParams) => {
    const currentFullParams = new URLSearchParams(window.location.search);
    if (currentFullParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
      set((state) => {
        const [existingMapMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.map.filterClasses
        });
        writeMeldFilterParamsToStorage("map", existingMapMeldFilterParams);
        return {
          meldFilterParams: __spreadProps(__spreadValues({}, state.meldFilterParams), {
            map: __spreadProps(__spreadValues({}, state.meldFilterParams.map), { priorParams: existingMapMeldFilterParams })
          })
        };
      });
    } else {
      set((state) => {
        const [existingCalendarMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.calendar.filterClasses,
          sortFilter: state.meldFilterParams.calendar.sortFilterClass,
          savedFilter: state.meldFilterParams.calendar.savedFilterClass
        });
        writeMeldFilterParamsToStorage("calendar", existingCalendarMeldFilterParams);
        return {
          meldFilterParams: __spreadProps(__spreadValues({}, state.meldFilterParams), {
            calendar: __spreadProps(__spreadValues({}, state.meldFilterParams.calendar), { priorParams: existingCalendarMeldFilterParams })
          })
        };
      });
    }
  };
  const { sortFilter: calendarMeldListSortFilter, filters: calendarMeldListFilters } = getDefaultMeldFilters({
    allMaint: [],
    onApplyAdditionalOnClick
  });
  const meldCalendarNearbyMeldFilters = [
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.status,
      overrides: {
        alwaysShow: true,
        // we limit status filtering options for nearby melds
        options: OpenStatuses.map((status) => ({
          label: MeldManagerStatusLabels[status],
          queryParamValue: status
        }))
      },
      onApplyAdditionalOnClick
    }),
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.priority,
      overrides: { alwaysShow: true },
      onApplyAdditionalOnClick
    })
  ];
  const calendarMeldListSavedFilter = new BaseSavedFiltersFilterClass({
    config: getMeldSavedFilterConfig({
      savedFilters: [],
      otherFilters: [calendarMeldListSortFilter, ...calendarMeldListFilters]
    }),
    overrides: { alwaysShow: true }
  });
  const meldFilterParams = {
    calendar: {
      sortFilterClass: calendarMeldListSortFilter,
      savedFilterClass: calendarMeldListSavedFilter,
      filterClasses: calendarMeldListFilters,
      type: "read-only",
      // these are the default
      priorParams: getInitialMeldFilterParams("calendar") || new URLSearchParams({
        [MeldFilterQueryKeys.status]: OpenStatuses.filter((status) => status !== MeldStatus.PENDING_COMPLETION).join(
          ","
        )
      })
    },
    map: {
      // since these filter classes don't require option populate from
      // api class we can initialize it as 'read-write'
      filterClasses: meldCalendarNearbyMeldFilters,
      type: "read-write",
      // these are the default
      priorParams: getInitialMeldFilterParams("map") || new URLSearchParams({
        [MeldFilterQueryKeys.status]: [MeldStatus.PENDING_ASSIGNMENT].join(",")
      })
    }
  };
  return {
    activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
    selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
    hideLargeNearbyMelds: getInitialHideNearbyMeldsState(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY),
    hideMapRowNearbyMelds: getInitialHideNearbyMeldsState(HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY),
    calendarOrMap: "calendar",
    selectedCalendarTimeFrame: getInitialSelectedTimeFrame(),
    selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
    scheduledSegments: [],
    dragAndDropState: null,
    pendingResdientAvailabilities: [],
    pendingRecommendedMeld: null,
    mapEventListRightpaneItems: [],
    altEventSelectedAgentsTime: null,
    priorRightPaneType: null,
    // since both use the url to filter we need to store/retrieve the prior state
    // when switching between the calendar and the map
    // we initialize 'read-only' classes which lack populated filter options, but
    // can read from the url
    meldFilterParams,
    onApplyFilterAdditionalOnClick: onApplyAdditionalOnClick,
    actions: {
      setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
      setmapEventListRightpaneItems: (items) => set({ mapEventListRightpaneItems: items }),
      setSelectedDate: (newDate) => {
        set({ selectedDate: newDate, pendingRecommendedMeld: null });
      },
      incrementDate: (selectedCalendarTimeFrame) => set((state) => ({
        selectedDate: addDays(new Date(state.selectedDate), selectedCalendarTimeFrame).valueOf(),
        pendingRecommendedMeld: null
      })),
      decrementDate: (selectedCalendarTimeFrame) => set((state) => ({
        selectedDate: subDays(new Date(state.selectedDate), selectedCalendarTimeFrame).valueOf(),
        pendingRecommendedMeldPlaceholder: null
      })),
      setDateToToday: () => set({
        selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
        pendingRecommendedMeld: null
      }),
      setCalendarSelectedTimeFrame: (newTimeFrame) => {
        setInitialSelectedTimeFrame(newTimeFrame);
        set({
          selectedCalendarTimeFrame: newTimeFrame,
          // for simplicity on timeframe change we just clear out any recommended meld events
          pendingRecommendedMeld: null
        });
      },
      setHideLargeNearbyMelds: (newValue) => {
        setInitialHideNearbyMeldsState(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY, newValue);
        set({ hideLargeNearbyMelds: newValue });
      },
      setHideMapRowNearbyMelds: (newValue) => {
        setInitialHideNearbyMeldsState(HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY, newValue);
        set({ hideMapRowNearbyMelds: newValue });
      },
      setSelectedVendorsAgents: (selected) => {
        setInitialSelectedVendorsOrAgents(selected);
        set((state) => {
          if (state.pendingRecommendedMeld) {
            const currentAgentId = state.pendingRecommendedMeld.personaId;
            if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
              return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
            }
          }
          return { selectedVendorsAgentsIds: selected };
        });
      },
      addSelectedVendorsAgents: (agentsVendorsToAdd) => {
        set((state) => {
          const result = {
            agents: state.selectedVendorsAgentsIds.agents,
            vendors: state.selectedVendorsAgentsIds.vendors
          };
          agentsVendorsToAdd.agents.forEach((agent) => {
            if (!result.agents.includes(agent)) {
              result.agents.push(agent);
            }
          });
          agentsVendorsToAdd.vendors.forEach((vendor) => {
            if (!result.vendors.includes(vendor)) {
              result.vendors.push(vendor);
            }
          });
          return { selectedVendorsAgentsIds: result };
        });
      },
      resetState: () => set({
        pendingRecommendedMeld: null,
        pendingResdientAvailabilities: [],
        mapEventListRightpaneItems: [],
        selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
        dragAndDropState: null
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addScheduledSegment: (segment) => set((state) => ({
        scheduledSegments: [...state.scheduledSegments, segment]
      })),
      removeScheduledSegment: (id) => set((state) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scheduledSegments: state.scheduledSegments.filter((segment) => segment.id !== id)
      })),
      startDraggingMeld: () => set({ dragAndDropState: "draggingMeld" }),
      startDraggingEvent: () => set({ dragAndDropState: "draggingEvent" }),
      stopDragging: () => set({ dragAndDropState: null }),
      startResizingEvent: () => set({ dragAndDropState: "resizingEvent" }),
      stopResizingEvent: () => set({ dragAndDropState: null }),
      // pending resident availability placeholders
      addPendingResidentAvailability: (pendingEventsToAdd) => set((state) => {
        return {
          pendingResdientAvailabilities: [
            ...state.pendingResdientAvailabilities,
            ...pendingEventsToAdd.map(
              (event) => ({
                type: "pending_offered_availability",
                start: event.start,
                end: event.end,
                startDate: new Date(event.start),
                endDate: new Date(event.end),
                start_moment: moment(event.start),
                end_moment: moment(event.end),
                description: "Offer this time to resident",
                personaId: event.agentId,
                personaType: "agent",
                key: event.tempId + event.agentId,
                tempId: event.tempId,
                coordinates: void 0
              })
            )
          ]
        };
      }),
      removePendingResidentAvailability: (tempId) => set((state) => {
        return {
          pendingResdientAvailabilities: [...state.pendingResdientAvailabilities].filter(
            (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
          )
        };
      }),
      upsertPendingResidentAvailability: ({ tempId, start, end, agents }) => set((state) => {
        let updatedAnEvent = false;
        const updatedAvailabilities = state.pendingResdientAvailabilities.map((existingEvent) => {
          if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
            return existingEvent;
          }
          updatedAnEvent = true;
          const updatedEvent = __spreadValues({}, existingEvent);
          updatedEvent.end_moment = end.clone();
          updatedEvent.end = end.toISOString();
          updatedEvent.start_moment = start.clone();
          updatedEvent.start = start.toISOString();
          return updatedEvent;
        });
        if (!updatedAnEvent) {
          agents.forEach((agent) => {
            updatedAvailabilities.push({
              type: "pending_offered_availability",
              start: start.toISOString(),
              startDate: start.toDate(),
              end: end.toISOString(),
              endDate: end.toDate(),
              start_moment: start,
              end_moment: end,
              description: "Offer this time to resident",
              personaId: agent.id,
              personaType: "agent",
              key: tempId + agent.id,
              tempId,
              coordinates: void 0
            });
          });
        }
        return { pendingResdientAvailabilities: updatedAvailabilities };
      }),
      clearPendingResidentAvailabilities: () => set({ pendingResdientAvailabilities: [] }),
      // calendar/map meld filter params
      setMeldFilterParams: (props) => set((state) => {
        const map = "newMap" in props && !!props.newMap ? props.newMap : state.meldFilterParams.map;
        const calendar = "newCalendar" in props && !!props.newCalendar ? props.newCalendar : state.meldFilterParams.calendar;
        return {
          meldFilterParams: {
            map,
            calendar
          }
        };
      }),
      populateMeldFilterParamsOptions: (updateFunc) => set((state) => {
        if (state.meldFilterParams.calendar.type === "read-write") {
          return {};
        }
        const newCalendarState = updateFunc(state);
        return {
          meldFilterParams: {
            map: state.meldFilterParams.map,
            calendar: newCalendarState
          }
        };
      }),
      initializePageWithMeldParam: ({ location, history }) => {
        const meldFilterParamsState = get().meldFilterParams;
        const currentParams = new URLSearchParams(location.search);
        if (currentParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
          const [existingMapMeldFilterParams, existingMapMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.map.filterClasses
          });
          if (existingMapMeldFilterParamsHasValues) {
            meldFilterParamsState.map.priorParams = existingMapMeldFilterParams;
          } else {
            for (const [key, value] of meldFilterParamsState.map.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        } else {
          const [existingCalendarMeldFilterParams, existingCalendarMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.calendar.filterClasses,
            sortFilter: meldFilterParamsState.calendar.sortFilterClass,
            savedFilter: meldFilterParamsState.calendar.savedFilterClass
          });
          if (existingCalendarMeldFilterParamsHasValues) {
            meldFilterParamsState.calendar.priorParams = existingCalendarMeldFilterParams;
          } else {
            for (const [key, value] of meldFilterParamsState.calendar.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        }
      },
      initializeAltEventSelectedAgentsTime: ({ agents, selectedTime, eventDescription }) => {
        set(() => ({ altEventSelectedAgentsTime: { selectedAgents: agents, selectedTime, eventDescription } }));
      },
      // update alt event data
      setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) => set((state) => {
        var _a, _b, _c, _d, _e, _f;
        const selectedAgents = newAgents || ((_a = state.altEventSelectedAgentsTime) == null ? void 0 : _a.selectedAgents) || [];
        const selectedDate = (newSelectedTime && "date" in newSelectedTime ? newSelectedTime.date : (_b = state.altEventSelectedAgentsTime) == null ? void 0 : _b.selectedTime.date) || /* @__PURE__ */ new Date();
        const selectedStart = newSelectedTime && "start" in newSelectedTime ? newSelectedTime.start : (_c = state.altEventSelectedAgentsTime) == null ? void 0 : _c.selectedTime.start;
        const selectedEnd = newSelectedTime && "end" in newSelectedTime ? newSelectedTime.end : (_d = state.altEventSelectedAgentsTime) == null ? void 0 : _d.selectedTime.end;
        if (!newAgents && !newSelectedTime) {
          return { altEventSelectedAgentsTime: null };
        }
        return {
          altEventSelectedAgentsTime: {
            selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
            eventId: eventId || ((_e = state.altEventSelectedAgentsTime) == null ? void 0 : _e.eventId),
            eventDescription: eventDescription || ((_f = state.altEventSelectedAgentsTime) == null ? void 0 : _f.eventDescription),
            selectedAgents
          }
        };
      }),
      updateOnRightPaneChange: (arg) => {
        set((state) => {
          const newState = {};
          if (arg.shouldClearPendingAltEvent) {
            newState.altEventSelectedAgentsTime = null;
          }
          if (arg.shouldClearPendingRecommendedMeldEvent) {
            newState.pendingRecommendedMeld = null;
          }
          const map = "newMap" in arg.newMeldFilterParams && !!arg.newMeldFilterParams.newMap ? arg.newMeldFilterParams.newMap : state.meldFilterParams.map;
          const calendar = "newCalendar" in arg && !!arg.newMeldFilterParams.newCalendar ? arg.newMeldFilterParams.newCalendar : state.meldFilterParams.calendar;
          newState.meldFilterParams = {
            map,
            calendar
          };
          return newState;
        });
      }
    }
  };
});
const useCalendarStateMeldFilterParams = () => useCalendarStateStore((state) => state.meldFilterParams);
const useCalendarStateMeldFilterApplyOnClick = () => useCalendarStateStore((state) => state.onApplyFilterAdditionalOnClick);
const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () => useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStateMapEventListRightpaneItems = () => useCalendarStateStore((state) => state.mapEventListRightpaneItems);
const useCalendarStatePendingOfferedAvailabilities = () => useCalendarStateStore((state) => state.pendingResdientAvailabilities);
const useCalendarStateSelectedDate = () => useCalendarStateStore((state) => state.selectedDate);
const useCalendarStateHideLargeNearbyMelds = () => useCalendarStateStore((state) => state.hideLargeNearbyMelds);
const useCalendarStateHideMapRowNearbyMelds = () => useCalendarStateStore((state) => state.hideMapRowNearbyMelds);
const useCalendarStateSelectedTimeFrame = (isMobile) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);
  return useCalendarStateStore((state) => {
    if (isMobile) {
      return 5;
    } else if (calendarOrMap === "large_map") {
      return 1;
    } else {
      return state.selectedCalendarTimeFrame;
    }
  });
};
const useCalendarStateSelectedVendorAgentIds = (isMobile) => useCalendarStateStore((state) => {
  if (isMobile) {
    if (state.selectedVendorsAgentsIds.agents.length > 0) {
      return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
    } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
      return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
    }
  }
  return state.selectedVendorsAgentsIds;
});
const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useCalendarDragAndDropState = () => useCalendarStateStore((state) => state.dragAndDropState);
export {
  useCalendarStateMeldFilterParams,
  useCalendarStateMeldFilterApplyOnClick,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMapEventListRightpaneItems,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedDate,
  useCalendarStateHideLargeNearbyMelds,
  useCalendarStateHideMapRowNearbyMelds,
  useCalendarStateSelectedTimeFrame,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useCalendarDragAndDropState,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY
};
