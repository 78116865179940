var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import {
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStateMeldFilterParams,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame
} from "../calendarStateStore";
import { BaseURLFilter } from "@pm-frontend/shared/components/FilterButtons/BaseFilterClasses";
import { differenceInDays, startOfDay } from "date-fns";
import {
  isValidCompositeId,
  isMaintenanceManagementAgent
} from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarMeldMapOpened, track } from "@pm-app/utils/analytics";
import { isAgentInMeldPropertyGroups } from "@pm-frontend/shared/utils/meld-utils";
import { useGetAgentMe } from "@pm-frontend/shared/hooks/queries/useGetAgents";
const CALENDAR_URL_PARAM_KEYS = {
  EVENT_ID: "event_id",
  AGENT_ID: "agent_id",
  VENDOR_ID: "vendor_id",
  PANE: "pane",
  MAP_OPEN: "map"
};
const CALENDAR_URL_PANE_PARAM_VALUES = {
  MELDS_LIST: "melds_list",
  AGENT_MELD_LIST: "agent_melds_list",
  AGENT_MELD_DETAILS: "agent_melds_details",
  VENDOR_MELD_LIST: "vendor_melds_list",
  VENDOR_MELD_DETAILS: "vendor_melds_details",
  RECOMMEND_MELDS: "recommend",
  RECOMMEND_MELD_DETAIL: "recommend_detail",
  ALTERNATIVE_EVENTS: "altevent",
  GOOGLE_EVENT: "google_event",
  OUTLOOK_EVENT: "outlook_event",
  MAP_EVENT_LIST: "map_event_list"
};
const LARGE_MAP_QUERY_PARAM_VALUE = "large_map";
const CALENDAR_PANE_TYPES = {
  MELD_DETAILS: "meldDetails",
  OFFER_AVAILABILITIES: "offerAvailabilities",
  NULL: "null",
  MELD_LIST: "meldsList",
  AGENT_MELD_LIST: "agentMeldsList",
  AGENT_MELD_DETAILS: "agentMelds_details",
  VENDOR_MELD_LIST: "vendorMeldsList",
  VENDOR_MELD_DETAILS: "vendorMelds_details",
  RECOMMEND: "recommendedMelds",
  RECOMMEND_DETAILS: "recommendedMelds_details",
  ALTERNATIVE_EVENT: "alternativeEvent",
  GOOGLE_EVENT: "googleCalendarEvent",
  OUTLOOK_EVENT: "outlookCalendarEvent",
  MAP_EVENT_LIST: "mapEventList"
};
const getSearchParamsFromCalendarPaneState = ({
  newRightpaneState,
  newMapState,
  currentMapState,
  location,
  isMobile,
  meldFilterParams
}) => {
  const search = new URLSearchParams(location.search);
  Object.values(CALENDAR_URL_PARAM_KEYS).forEach((key) => {
    search.delete(key);
  });
  let pathname = LinkHelper.normalize(RouteUrls.meldCalendarList);
  switch (newRightpaneState.type) {
    case CALENDAR_PANE_TYPES.MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELD_DETAIL);
      break;
    case CALENDAR_PANE_TYPES.OFFER_AVAILABILITIES:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendarMeldAvailabilities({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS);
      break;
    case CALENDAR_PANE_TYPES.GOOGLE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.OUTLOOK_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.MAP_EVENT_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST);
      break;
    case CALENDAR_PANE_TYPES.AGENT_MELD_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_LIST);
      search.set(CALENDAR_URL_PARAM_KEYS.AGENT_ID, newRightpaneState.agentId);
      break;
    case CALENDAR_PANE_TYPES.AGENT_MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_DETAILS);
      search.set(CALENDAR_URL_PARAM_KEYS.AGENT_ID, newRightpaneState.agentId);
      break;
    case CALENDAR_PANE_TYPES.VENDOR_MELD_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_LIST);
      search.set(CALENDAR_URL_PARAM_KEYS.VENDOR_ID, newRightpaneState.vendorId);
      break;
    case CALENDAR_PANE_TYPES.VENDOR_MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_DETAILS);
      search.set(CALENDAR_URL_PARAM_KEYS.VENDOR_ID, newRightpaneState.vendorId);
      break;
    case CALENDAR_PANE_TYPES.ALTERNATIVE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      }
      break;
    case CALENDAR_PANE_TYPES.NULL:
      break;
    case CALENDAR_PANE_TYPES.MELD_LIST:
      if (isMobile) {
        search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST);
      }
      if (newRightpaneState.filterId) {
        search.set("saved_filter", newRightpaneState.filterId.toString());
      }
      break;
    default:
      break;
  }
  if (newMapState === LARGE_MAP_QUERY_PARAM_VALUE || newMapState === void 0 && currentMapState === LARGE_MAP_QUERY_PARAM_VALUE) {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, LARGE_MAP_QUERY_PARAM_VALUE);
  } else if (newMapState && isValidCompositeId(newMapState) || newMapState === void 0 && isValidCompositeId(currentMapState)) {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, newMapState || currentMapState);
  }
  if (newMapState === LARGE_MAP_QUERY_PARAM_VALUE) {
    BaseURLFilter.deleteAllFilterValues({
      paramsToMutate: search,
      filters: meldFilterParams.calendar.filterClasses,
      sortFilter: meldFilterParams.calendar.sortFilterClass,
      savedFilterClass: meldFilterParams.calendar.savedFilterClass
    });
    const priorParams = meldFilterParams.map.priorParams;
    if (priorParams) {
      for (const [key, value] of priorParams.entries()) {
        search.set(key, value);
      }
    }
  } else if (newMapState === "calendar") {
    BaseURLFilter.deleteAllFilterValues({ paramsToMutate: search, filters: meldFilterParams.map.filterClasses });
    const priorParams = meldFilterParams.calendar.priorParams;
    if (priorParams) {
      for (const [key, value] of priorParams.entries()) {
        search.set(key, value);
      }
    }
  }
  return { search: search.toString(), pathname };
};
const getCalendarOrMapState = (searchParams, isMobile) => {
  if (isMobile) {
    return "calendar";
  }
  const queryParamValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.MAP_OPEN);
  if (!queryParamValue) {
    return "calendar";
  } else if (queryParamValue === LARGE_MAP_QUERY_PARAM_VALUE) {
    return LARGE_MAP_QUERY_PARAM_VALUE;
  } else if (isValidCompositeId(queryParamValue)) {
    return queryParamValue;
  }
  return "calendar";
};
const shouldClearPendingRecommendedMeldEvent = (pendingRecommendedEvent, newRightpaneState) => {
  if (!pendingRecommendedEvent) {
    return false;
  } else if (!newRightpaneState) {
    return false;
  } else if (newRightpaneState.type === "recommendedMelds" || newRightpaneState.type === "recommendedMelds_details") {
    return false;
  }
  return true;
};
const useGetSetActivePaneAndMap = () => {
  const eventMetaData = useGetCalendarEventMetaData();
  const isMobile = useIsMobile();
  const { updateOnRightPaneChange } = useCalendarStateActions();
  const pendingRecommendedEvent = useCalendarStatePendingRecommendedMeld();
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();
  const meldFilterParams = useCalendarStateMeldFilterParams();
  const detailsPaneMatch = useRouteMatch({ path: ReactRouterDomRoutes.meldCalendarMeldDetails });
  const availabilitiesPaneMatch = useRouteMatch(
    ReactRouterDomRoutes.meldCalendarMeldAvailabilities
  );
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);
  const paneUrlValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.PANE);
  let rightPaneState = { type: "null" };
  if (availabilitiesPaneMatch) {
    rightPaneState = { type: "offerAvailabilities", meldId: availabilitiesPaneMatch.params.meldId };
  } else if (detailsPaneMatch) {
    if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELD_DETAIL) {
      rightPaneState = { type: "recommendedMelds_details", meldId: detailsPaneMatch.params.meldId };
    } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_DETAILS) {
      const agentId = searchParams.get(CALENDAR_URL_PARAM_KEYS.AGENT_ID);
      rightPaneState = { type: "agentMelds_details", meldId: detailsPaneMatch.params.meldId, agentId: agentId || "" };
    } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_DETAILS) {
      const vendorId = searchParams.get(CALENDAR_URL_PARAM_KEYS.VENDOR_ID);
      rightPaneState = {
        type: "vendorMelds_details",
        meldId: detailsPaneMatch.params.meldId,
        vendorId: vendorId || ""
      };
    } else {
      rightPaneState = { type: "meldDetails", meldId: detailsPaneMatch.params.meldId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "alternativeEvent", mode: "edit", eventId };
    } else {
      rightPaneState = { type: "alternativeEvent", mode: "add" };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "googleCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "outlookCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_LIST) {
    const agentId = searchParams.get(CALENDAR_URL_PARAM_KEYS.AGENT_ID);
    if (agentId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.AGENT_MELD_LIST, agentId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_LIST) {
    const vendorId = searchParams.get(CALENDAR_URL_PARAM_KEYS.VENDOR_ID);
    if (vendorId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.VENDOR_MELD_LIST, vendorId };
    }
  } else if (isMobile) {
    rightPaneState = { type: "null" };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.RECOMMEND };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MAP_EVENT_LIST };
  } else {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  }
  const updateRightPaneURL = ({
    newMapState,
    newRightpaneState,
    action = "push"
  }) => {
    const clearPendingRecMeld = shouldClearPendingRecommendedMeldEvent(pendingRecommendedEvent, newRightpaneState);
    const clearAltEvent = newRightpaneState && newRightpaneState.type !== "alternativeEvent" && !!pendingAltEvent;
    const { search, pathname } = getSearchParamsFromCalendarPaneState({
      newRightpaneState: newRightpaneState || rightPaneState,
      newMapState,
      currentMapState: calendarOrMap,
      location,
      isMobile,
      meldFilterParams
    });
    if (newMapState) {
      if (newMapState === LARGE_MAP_QUERY_PARAM_VALUE) {
        track(CalendarMeldMapOpened(__spreadValues({ type: "large" }, eventMetaData)));
        const currentCalendarParams = BaseURLFilter.getFilterValues({
          location,
          filters: meldFilterParams.calendar.filterClasses,
          sortFilter: meldFilterParams.calendar.sortFilterClass,
          savedFilterClass: meldFilterParams.calendar.savedFilterClass,
          savedFilter: void 0
        });
        updateOnRightPaneChange({
          shouldClearPendingRecommendedMeldEvent: clearPendingRecMeld,
          shouldClearPendingAltEvent: !!clearAltEvent,
          newMeldFilterParams: {
            newCalendar: __spreadProps(__spreadValues({}, meldFilterParams.calendar), {
              priorParams: currentCalendarParams
            })
          }
        });
      } else if (newMapState === "calendar") {
        const currentMapParams = BaseURLFilter.getFilterValues({
          location,
          filters: meldFilterParams.map.filterClasses,
          savedFilter: void 0
        });
        updateOnRightPaneChange({
          shouldClearPendingRecommendedMeldEvent: clearPendingRecMeld,
          shouldClearPendingAltEvent: !!clearAltEvent,
          newMeldFilterParams: {
            newMap: __spreadProps(__spreadValues({}, meldFilterParams.map), {
              priorParams: currentMapParams
            })
          }
        });
      } else if (isValidCompositeId(newMapState)) {
        track(
          CalendarMeldMapOpened(__spreadValues({
            type: isMaintenanceManagementAgent({ composite_id: newMapState }) ? "small-agent" : "small-vendor"
          }, eventMetaData))
        );
      }
    }
    if (action === "push") {
      history.push({
        pathname,
        search
      });
    } else if (action === "replace") {
      history.replace({
        pathname,
        search
      });
    }
  };
  return { rightPaneState, calendarOrMap, updateRightPaneURL };
};
const useGetCalendarEventMetaData = () => {
  const isMobile = useIsMobile();
  const selectedTimeframe = useCalendarStateSelectedTimeFrame(isMobile);
  const selectedDate = useCalendarStateSelectedDate();
  const daysOffsetFromCurrentDay = differenceInDays(startOfDay(new Date(selectedDate)), startOfDay(/* @__PURE__ */ new Date()));
  return {
    isMobile,
    selectedTimeframe,
    daysOffsetFromCurrentDay
  };
};
function useIsEventViewableDueToPropertyGroups(event) {
  const { data: currentAgentDetails } = useGetAgentMe();
  if (!currentAgentDetails) {
    return true;
  }
  if (event.meld) {
    return isAgentInMeldPropertyGroups(event.meld, currentAgentDetails);
  } else {
    return true;
  }
}
export {
  useIsEventViewableDueToPropertyGroups,
  useGetCalendarEventMetaData,
  useGetSetActivePaneAndMap,
  getCalendarOrMapState,
  CALENDAR_URL_PANE_PARAM_VALUES,
  CALENDAR_PANE_TYPES,
  CALENDAR_URL_PARAM_KEYS
};
