import React from "react";
import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiLink, EuiImage } from "@elastic/eui";
import { PmEmptyButton, PmEmptyButtonProps } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { EuiPanelProps } from "@elastic/eui/src/components/panel/panel";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;

type BannerContentProps = {
  backgroundColor?: string;
  bannerButtonProps?: DistributiveOmit<PmEmptyButtonProps, "text" | "data-testid"> & {
    text?: string;
    dataTestId?: string;
  };
  onClose?: () => void;
} & EuiPanelProps;

type PmBannerProps = BannerContentProps & {
  showMultipleBanners?: boolean;
  "data-testid"?: string;
};

const DEFAULT_SUBMIT_BUTTON_TEXT = "Okay";

const BannerContent = ({
  backgroundColor = colors.brand.meldBlue,
  bannerButtonProps,
  children,
  onClose,
  ...rest
}: BannerContentProps) => {
  const isMobile = useIsMobile();

  return (
    <EuiPanel
      hasBorder={true}
      hasShadow={false}
      style={{ backgroundColor: backgroundColor || colors.brand.meldBlue, padding: "11px" }}
      {...rest}
    >
      {isMobile ? (
        <EuiFlexGroup gutterSize="s" direction="column" responsive={false}>
          {/*top half*/}
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
              {/*left hand side*/}
              <EuiFlexItem grow={true}>{children}</EuiFlexItem>
              {/*right hand side*/}
              <EuiFlexItem grow={false} style={{ minWidth: "1px" }}>
                <EuiLink onClick={onClose}>
                  <EuiImage
                    src={
                      backgroundColor !== colors.brand.meldBlue
                        ? URL.getStatic("icons/close_no_fill.svg")
                        : URL.getStatic("icons/close_white.svg")
                    }
                    alt="Coordinator Edit"
                    style={{ minWidth: "16px" }}
                  />
                </EuiLink>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          {/*bottom half*/}
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
              <EuiFlexItem grow={false}>
                {bannerButtonProps && (
                  <PmEmptyButton
                    {...{
                      ...bannerButtonProps,
                      color: "primary",
                      "data-testid": bannerButtonProps.dataTestId || "banner-submit-button",
                      text: bannerButtonProps.text || DEFAULT_SUBMIT_BUTTON_TEXT,
                      textSize: "14px",
                      padding: "5px 22.29px",
                    }}
                  />
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexGroup gutterSize="s" direction="row" alignItems="center" responsive={false}>
          {/*left hand side*/}
          <EuiFlexItem>{children}</EuiFlexItem>
          {/*right hand side*/}
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
              <EuiFlexItem grow={false}>
                {bannerButtonProps && (
                  <PmEmptyButton
                    {...{
                      ...bannerButtonProps,
                      color: "primary",
                      "data-testid": bannerButtonProps.dataTestId || "banner-submit-button",
                      text: bannerButtonProps.text || DEFAULT_SUBMIT_BUTTON_TEXT,
                      textSize: "14px",
                      padding: "5px 22.29px",
                    }}
                  />
                )}
              </EuiFlexItem>
              <EuiFlexItem style={{ justifyContent: "center" }} grow={false}>
                <EuiLink onClick={onClose}>
                  <EuiImage
                    src={
                      backgroundColor !== colors.brand.meldBlue
                        ? URL.getStatic("icons/close_no_fill.svg")
                        : URL.getStatic("icons/close_white.svg")
                    }
                    alt="Coordinator Edit"
                    style={{ minWidth: "16px" }}
                  />
                </EuiLink>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};

const PmBanner = ({
  backgroundColor = colors.brand.meldBlue,
  showMultipleBanners,
  bannerButtonProps,
  children,
  onClose,
  ...rest
}: PmBannerProps) => {
  return (
    <EuiFlexGroup gutterSize="none" direction="column" responsive={false}>
      <EuiFlexItem grow={false}>
        <BannerContent
          backgroundColor={backgroundColor}
          bannerButtonProps={bannerButtonProps}
          onClose={onClose}
          {...rest}
        >
          {children}
        </BannerContent>
      </EuiFlexItem>
      {showMultipleBanners && (
        <EuiFlexItem
          grow={false}
          style={{
            backgroundColor: backgroundColor,
            height: "12px",
            marginLeft: "11px",
            marginRight: "11px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        />
      )}
    </EuiFlexGroup>
  );
};

export { PmBanner };
