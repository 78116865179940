/* eslint-disable */
import _ from "lodash";
import AltContainer from "alt-container";
import AssignmentRequestActions from "../../../meld/actions/assignment-request-actions";
import AssignmentRequestStore from "../../../meld/stores/assignment-request-store";
import AssignmentUtils from "../../../availability/utils/vendor-assignment-request-utils";
import AvailabilitySelector from "../../../availability/components/availability-selector";
import AvailabilityUtils from "../../../availability/utils/availability-utils";
import * as C from "../../../constants";
import CantScheduleWarning from "../../../availability/components/cant-schedule-warning";
import createReactClass from "create-react-class";
import DetailBody from "../../../common/components/detail-body";
import DetailFooter from "../../../common/components/detail-footer";
import DetailHeader from "../../../common/components/detail-header";
import DetailLabel from "../../../common/components/detail-label";
import DetailView from "../../../common/components/detail-view";
import DialogBox from "../../../dialog-box";
import DocumentTitle from "react-document-title";
import ErrorRenderingUtils from "../../../utils/error-rendering-utils";
import HistoryUtils from "../../../common/utils/history-utils";
import I from "immutable";
import MeldUtils from "../../../meld/utils/meld-utils";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ScheduleFromRequestPicker from "./schedule-from-request-picker";
import SchedulerMeldDetails from "./scheduler-meld-details";
import Status from "../../../mixins/status";
import TenantContactsTable from "../../../availability/components/tenant-contacts-table";
import vendorAppointmentApi from "../../../availability/apis/vendor-appointment-api";
import withAppointmentRequestFromAssignment from "../../../availability/components/with-appointment-request-from-assignment";
import { compose, mapProps } from "recompose";
import { LargeLoader } from "../../../loaders";
import { NoTenantWarningModal } from "../../../availability/components/no-tenant-warning-modal";
import { parseQuery } from "../../../common/utils/location-utils";
import { withRouter } from "react-router-dom";
import ButtonContainer from "../../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../../app/components/buttons/Button";
import { ErrorHandler } from "../../../../../app/utils/ErrorHandler";
import {
  track,
  VendorAppointmentWindowChanged,
  VendorAvailabilityDeferred,
  VendorAvailabilitySubmitted,
  VendorChangesCleared,
  VendorMeldScheduled,
  VendorCreatedMultipleAppointmentsOnInitialSchedule,
  VendorCreatedAdditionalAppointment,
  VendorRescheduledAppointment,
  VendorSentAvailabilityWithRequiredAmountOfAppointments,
} from "../../../../../app/utils/analytics";
import Features from "@pm-assets/js/common/feature-flags";
import meldActions from "../../../meld/actions/meld-actions";

let TenantContactInfo = createReactClass({
  propTypes: {
    meld: PropTypes.any.isRequired,
  },

  render() {
    if (MeldUtils.isTenantPresenceRequired(this.props.meld) && !MeldUtils.hasRegisteredTenant(this.props.meld)) {
      return <TenantContactsTable tenants={MeldUtils.getTenants(this.props.meld)} />;
    } else {
      return null;
    }
  },
});

let AcceptMeldAvailability = createReactClass({
  loadingStore: AssignmentRequestStore,

  mixins: [ErrorRenderingUtils, Status],

  getDefaultProps() {
    return {
      defaultIncrement: 8,
    };
  },

  getInitialState() {
    const isAddingAppointment = parseQuery(this.props.location).addingAppointment === "true";
    const isReschedulingAppointment = parseQuery(this.props.location).reschedulingAppointment === "true";
    const reschedulingAppointmentId = parseQuery(this.props.location).vendorAppointmentId;

    const initialData = this.props.assignment ? this.props.assignment.get("vendor_availability_segments") : I.List();

    let data = this.props.assignment;
    let increment = this.getInitialIncrementSize(data.get("vendor_availability_segments"));
    let meld = data.get("meld");

    if (data) {
      let more_availability_requested = meld.get("status") === C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY;
      let updatedSegments = data.get("vendor_availability_segments").map((segment) => {
        if (
          meld.get("tenant_presence_required") &&
          meld.get("has_registered_tenant") &&
          (more_availability_requested || segment.get("scheduled_vendor_appointment"))
        ) {
          return segment.setIn(["event", "type"], C.DEFAULT_EVENT_TYPE);
        } else {
          return segment.setIn(["event", "type"], C.DEFAULT_EVENT_TYPE);
        }
      });
      data = data.set("vendor_availability_segments", updatedSegments);
    }

    let constraints;
    if (!parseQuery(this.props.location).markScheduled) {
      constraints = {
        vendor_availability_segments: {
          minAvailabilties: {
            getMeld: () => this.getMeld(),
          },
        },
      };
    }

    return {
      data:
        data ||
        I.fromJS({
          vendor_availability_segments: [],
        }),
      increment,
      constraints,
      viewCalendar: false,
      appointmentsRequired: 1,
      cid: _.uniqueId("AcceptMeldAvailability_"),
      showUnregisteredTenantModal: MeldUtils.isTenantPresenceRequired(meld) && !MeldUtils.hasRegisteredTenant(meld),
      tempStoredSegments: [],
      isAddingAppointment,
      isReschedulingAppointment,
      reschedulingAppointmentId,
      existingSegments: isAddingAppointment ? initialData || I.List() : I.List(),
      showSchedulingLoader: false,
    };
  },

  render() {
    const canAddMultipleEvents = this.canAddMultipleEvents();
    let { appointmentRequest } = this.props;
    let hasValidRequests = false;
    const appointment = this.getCurrentAppointment();
    let meld = this.getMeld();
    const briefDescription = meld.get("brief_description");
    let events = this.getEvents();
    events = events ? events.toJS() : [];

    if (appointmentRequest) {
      const lastAvailability = appointmentRequest
        .get("availabilities")
        .sort(AvailabilityUtils.dtstartDifference)
        .last();
      hasValidRequests = AvailabilityUtils.isAvailabilityScheduleable(lastAvailability);
    }

    if (!MeldUtils.canVendorScheduleAvailability(meld)) {
      return <CantScheduleWarning meld={meld} />;
    }

    const showRequestPicker =
      hasValidRequests && !this.state.viewCalendar && !parseQuery(this.props.location).markScheduled;
    const canShowRequestPicker = hasValidRequests && this.state.viewCalendar;

    if (showRequestPicker) {
      return (
        <ScheduleFromRequestPicker
          appointmentRequest={this.props.appointmentRequest}
          meld={meld}
          history={this.props.history}
          appointment={appointment}
          onOptOut={this.handleNoRequestChosen}
          onScheduled={this.handleAppointmentRequestScheduled}
        />
      );
    }

    const loading = this.isLoading();
    return (
      <DocumentTitle title={`Schedule - ${briefDescription}`}>
        <DetailView mediumClass={12} largeClass={10}>
          <DetailHeader>
            <h2 className="detail-title">Schedule</h2>
          </DetailHeader>
          <DetailBody>
            <SchedulerMeldDetails meld={meld} appointment={appointment} unit={meld.get("unit")} />
            <hr className="default-separator" />
            <div className="row">
              <div className="columns small-12 appointment-instructions">
                <TenantContactInfo meld={meld} />
                <div className="detail-section-title">
                  {canAddMultipleEvents ? "Choose Availability" : "Choose Appointment"}
                </div>

                {canAddMultipleEvents && (
                  <p className="copy">
                    Availabilities will be sent to the tenant for them to choose from. You will be notified
                    automatically when an appointment is scheduled.
                  </p>
                )}
                {!canAddMultipleEvents && (
                  <p className="copy">
                    Confirm an appointment time by making a selection from the calendar below. Tenants and property
                    managers will be automatically notified when an appointment is scheduled.
                  </p>
                )}

                <p className="copy">
                  Select a time by hovering over the calendar and clicking on a time slot.
                  {canShowRequestPicker && (
                    <span>
                      &nbsp;Don't want to wait for the tenant? Use{" "}
                      <a onClick={this.handleViewInstantSchedule}>Instant Scheduling</a> to lock in a time now.
                    </span>
                  )}
                </p>

                <div className="availability-selection-tools">
                  <DetailLabel>Appointment window</DetailLabel>
                  <div className="time-increment-select-container">
                    <select
                      value={String(this.state.increment)}
                      onChange={this.onTimeIncrementChanged}
                      className="time-increment"
                    >
                      <option value="1">15 Minutes</option>
                      <option value="2">30 Minutes</option>
                      <option value="3">45 Minutes</option>
                      <option value="4">1 Hour</option>
                      <option value="5">1 Hour 15 Minutes</option>
                      <option value="6">1 Hour 30 Minutes</option>
                      <option value="7">1 Hour 45 Minutes</option>
                      <option value="8">2 Hours</option>
                      <option value="9">2 Hours 15 Minutes</option>
                      <option value="10">2 Hours 30 Minutes</option>
                      <option value="11">2 Hours 45 Minutes</option>
                      <option value="12">3 Hours</option>
                      <option value="13">3 Hours 15 Minutes</option>
                      <option value="14">3 Hours 30 Minutes</option>
                      <option value="15">3 Hours 45 Minutes</option>
                      <option value="16">4 Hours</option>
                      <option value="17">4 Hours 15 Minutes</option>
                      <option value="18">4 Hours 30 Minutes</option>
                      <option value="19">4 Hours 45 Minutes</option>
                      <option value="20">5 Hours</option>
                      <option value="21">5 Hours 15 Minutes</option>
                      <option value="22">5 Hours 30 Minutes</option>
                      <option value="23">5 Hours 45 Minutes</option>
                      <option value="24">6 Hours</option>
                      <option value="25">6 Hours 15 Minutes</option>
                      <option value="26">6 Hours 30 Minutes</option>
                      <option value="27">6 Hours 45 Minutes</option>
                      <option value="28">7 Hours</option>
                      <option value="29">7 Hours 15 Minutes</option>
                      <option value="30">7 Hours 30 Minutes</option>
                      <option value="31">7 Hours 45 Minutes</option>
                      <option value="32">8 Hours</option>
                    </select>
                  </div>
                </div>
                <AvailabilitySelector
                  events={events}
                  onEventDeleted={this.onEventDeleted}
                  onEventClicked={this.onEventClicked}
                  increment={this.state.increment}
                />
                {this.renderUnregisteredTenantModal()}
                {this.renderTimeIncrementModal()}

                {canShowRequestPicker && (
                  <p className="copy center">
                    Don't want to wait for the tenant? Use{" "}
                    <a onClick={this.handleViewInstantSchedule}>Instant Scheduling</a> to lock in a time now.
                  </p>
                )}
              </div>
            </div>

            {this.renderErrors()}

            {Features.isMultipleAppointmentsEnabled() &&
              canAddMultipleEvents &&
              this.getMeld().get("meld_type") !== "ESTIMATE" && (
                <div style={{ marginBottom: "20px" }}>
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    Appointments Required
                  </h3>
                  <div style={{ position: "relative", display: "inline-block", width: "100%", maxWidth: "200px" }}>
                    <select
                      value={this.state.appointmentsRequired}
                      onChange={this.onAppointmentsRequiredChanged}
                      style={{
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                      data-test="vendor_hub.add_more_availability.appointments_required"
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            {(this.getSegmentCount() > 0 || this.state.tempStoredSegments.length > 0) && this.renderEventTimes()}

            <DetailFooter>
              {Features.isMultipleAppointmentsEnabled() &&
                canAddMultipleEvents &&
                this.getMeld().get("meld_type") !== "ESTIMATE" &&
                this.renderWarningMessage()}
              <ButtonContainer>
                <Button
                  data-test="vendor_hub.add_more_availability.action.cancel"
                  buttonType={ButtonTypes.Cancel}
                  onClick={this.cancel}
                  disabled={this.state.showSchedulingLoader || loading}
                >
                  Not Now
                </Button>
                <Button
                  data-test="vendor_hub.add_more_availability.action.reset"
                  buttonType={ButtonTypes.Danger}
                  onClick={this.resetData}
                  disabled={this.state.showSchedulingLoader || loading}
                >
                  Clear Changes
                </Button>

                <Button
                  data-test="vendor_hub.add_more_availability.action.save"
                  buttonType={ButtonTypes.Primary}
                  disabled={
                    this.state.showSchedulingLoader ||
                    (this.getSegmentCount() === 0 && this.state.tempStoredSegments.length === 0) ||
                    (events.length === 0 && this.state.isReschedulingAppointment) ||
                    loading ||
                    (Features.isMultipleAppointmentsEnabled() &&
                      this.canAddMultipleEvents() &&
                      this.getSegmentCount() < this.state.appointmentsRequired) ||
                    (Features.isMultipleAppointmentsEnabled() &&
                      this.canAddMultipleEvents() &&
                      this.getSegmentCount() < 2)
                  }
                  onClick={this.prepareSubmit(this.save)}
                >
                  {this.state.showSchedulingLoader || loading
                    ? "Saving..."
                    : this.canAddMultipleEvents()
                    ? "Send Availability"
                    : "Schedule"}{" "}
                </Button>
              </ButtonContainer>
            </DetailFooter>
          </DetailBody>
        </DetailView>
      </DocumentTitle>
    );
  },

  handleNoRequestChosen() {
    track(
      VendorAvailabilityDeferred({
        meldId: this.getMeld().get("id"),
        pageName: "vendor_hub>scheduling_calendar>availability",
      })
    );
    this.setState({ viewCalendar: true });
  },

  handleViewInstantSchedule(e) {
    e.preventDefault();
    this.setState({ viewCalendar: false });
  },

  getSegmentIdsToKeep() {
    let meld = this.getMeld();
    if (
      MeldUtils.isTenantPresenceRequired(meld) &&
      MeldUtils.hasRegisteredTenant(meld) &&
      !parseQuery(this.props.location).markScheduled
    ) {
      return this.getSegments()
        .filter((segment) => segment.getIn(["event", "id"]))
        .map((segment) => segment.get("id"))
        .toJS();
    } else {
      return [];
    }
  },

  getNewSegments() {
    return this.getSegments()
      .filter((segment) => segment.getIn(["event", "_cid"]))
      .toJS();
  },

  fulfillIncrementRequest() {
    let requestedIncrement = this.state.requestedTimeIncrement;

    this.setState({
      increment: requestedIncrement,
      requestedTimeIncrement: null,
    });

    this.resetData();
  },

  resetData() {
    track(
      VendorChangesCleared({
        meldId: this.getMeld().get("id"),
        pageName: "vendor_hub>scheduling_calendar>availability",
      })
    );

    if (!this.state.isAddingAppointment && parseQuery(this.props.location).markScheduled) {
      const existingEvents = this.getSegments().filter((segment) => segment.getIn(["event", "id"]));
      this.setState({
        data: this.state.data.set("vendor_availability_segments", existingEvents),
        tempStoredSegments: [],
      });
    } else {
      this.setState({ data: this.getInitialState().data });
    }
  },

  onEventClicked(newEvent) {
    let event = {
      dtstart: newEvent.dtstart,
      dtend: newEvent.dtend,
      type: C.DEFAULT_EVENT_TYPE,
      _cid: _.uniqueId("event_"),
    };

    let newSegment = I.fromJS({ event });

    if (this.state.isReschedulingAppointment) {
      this.setState((prevState) => ({
        data: prevState.data.update("vendor_availability_segments", (segments) =>
          segments.map((segment) => {
            if (
              segment.getIn(["scheduled_vendor_appointment", "id"]) ===
              parseInt(this.state.reschedulingAppointmentId, 10)
            ) {
              return newSegment.setIn(
                ["scheduled_vendor_appointment", "id"],
                parseInt(this.state.reschedulingAppointmentId, 10)
              );
            }
            return segment;
          })
        ),
      }));
    } else if (this.state.isAddingAppointment) {
      this.setState((prevState) => ({
        data: prevState.data.update("vendor_availability_segments", (segments) =>
          segments.filter((segment) => segment.getIn(["event", "id"])).push(newSegment)
        ),
      }));
    } else if (!this.state.isAddingAppointment && parseQuery(this.props.location).markScheduled) {
      if (Features.isMultipleAppointmentsEnabled() && this.getMeld().get("meld_type") !== "ESTIMATE") {
        this.setState((prevState) => ({
          tempStoredSegments: [...prevState.tempStoredSegments, newSegment.toJS()],
        }));
      } else {
        const appointment = this.props.appointment;
        let updatedSegments = this.getSegments()
          .filter((segment) => {
            let apptId = segment.getIn(["scheduled_vendor_appointment", "id"]);
            return appointment && apptId && apptId !== this.props.appointment.get("id");
          })
          .push(newSegment);
        this.setState({ data: this.setSegments(updatedSegments) });
      }
    } else {
      this.handleNewSegment(newSegment);
    }
  },

  _addSegment(newSegment) {
    let updatedSegments = this.getSegments().push(newSegment);
    this.setState({ data: this.setSegments(updatedSegments) });
  },

  onEventDeleted(event) {
    if (this.state.isReschedulingAppointment) {
      return;
    }

    const updatedCurrentSegments = this.getSegments().filter((segment) => {
      const eventMatches =
        (event._cid && segment.getIn(["event", "_cid"]) === event._cid) ||
        (event.id && segment.getIn(["event", "id"]) === event.id);
      return !eventMatches;
    });

    const updatedTempSegments = this.state.tempStoredSegments.filter((segment) => {
      const eventMatches =
        (event._cid && segment.getIn(["event", "_cid"]) === event._cid) ||
        (event.id && segment.getIn(["event", "id"]) === event.id);
      return !eventMatches;
    });

    this.setState({
      data: this.setSegments(updatedCurrentSegments),
      tempStoredSegments: updatedTempSegments,
    });
  },

  onTimeIncrementChanged(e) {
    const newIncrement = Number.parseInt(e.target.value);
    if (this.getSegments().size) {
      this.setState({ requestedTimeIncrement: newIncrement });
    } else {
      this.setState({ increment: newIncrement });
    }
    track(
      VendorAppointmentWindowChanged({
        meldId: this.getMeld().get("id"),
        pageName: "vendor_hub>scheduling_calendar>availability",
      })
    );
  },

  getInitialIncrementSize(segments) {
    let increment = this.props.defaultIncrement;

    if (segments.size) {
      let dtstart = moment(segments.first().getIn(["event", "dtstart"]));
      let dtend = moment(segments.first().getIn(["event", "dtend"]));

      increment = Number.parseInt(dtend.diff(dtstart, "minutes") / 15);

      if (!Number.isInteger(increment) || increment <= 0) {
        increment = this.props.defaultIncrement;
      }
    }

    return increment;
  },

  renderUnregisteredTenantModal() {
    if (this.state.showUnregisteredTenantModal) {
      return (
        <NoTenantWarningModal
          tenants={MeldUtils.getTenants(this.getMeld())}
          onCloseClick={() => this.setState({ showUnregisteredTenantModal: false })}
        />
      );
    } else {
      return null;
    }
  },

  getMeld() {
    return this.props.assignment.get("meld");
  },

  onAppointmentsRequiredChanged(e) {
    this.setState({ appointmentsRequired: Number(e.target.value) });
  },

  getSegments() {
    return this.state.data.get("vendor_availability_segments");
  },

  getSegmentCount() {
    return this.getSegments().size;
  },

  setSegments(segments) {
    return this.state.data.set("vendor_availability_segments", segments);
  },

  renderTimeIncrementModal() {
    if (this.state.requestedTimeIncrement) {
      if (AssignmentUtils.isAccepted(this.props.assignment)) {
        return (
          <DialogBox
            headerText="Estimated Job Duration"
            cancelBtnText="Close"
            confirmText="Estimated job duration can't be changed once a meld has been accepted."
            closeClick={() => this.setState({ requestedTimeIncrement: null })}
          />
        );
      }
      if (this.getSegments().size) {
        return (
          <DialogBox
            headerText="Confirm Estimated Job Duration Change"
            confirmText="Changing the estimated job duration will clear all appointment times."
            dangerBtnText="Change &amp; Clear"
            closeClick={() => this.setState({ requestedTimeIncrement: null })}
            dangerClick={this.fulfillIncrementRequest}
          />
        );
      }
    }
  },

  getEvents() {
    const currentSegments = this.getSegments();
    const tempSegments = I.fromJS(this.state.tempStoredSegments);

    const shouldShowExistingEvents = !this.state.isAddingAppointment && !parseQuery(this.props.location).markScheduled;

    const filteredCurrentSegments = shouldShowExistingEvents
      ? currentSegments
      : currentSegments.filter((segment) => !segment.getIn(["event", "id"]));

    return filteredCurrentSegments.concat(tempSegments).map((segment, index) => {
      const event = segment.get("event");
      return event.set("isTempStored", index >= filteredCurrentSegments.size);
    });
  },

  formatDateTime(dtstart, dtend) {
    const start = moment(dtstart);
    const end = moment(dtend);
    return `${start.format("MMMM D, YYYY, h:mm A")} - ${end.format("h:mm A")}`;
  },

  getAppointmentHeaderText(segmentsToDisplay) {
    if (
      !this.state.isAddingAppointment &&
      parseQuery(this.props.location).markScheduled &&
      !this.state.isReschedulingAppointment
    ) {
      return segmentsToDisplay.size === 1
        ? "The following appointment will be scheduled:"
        : segmentsToDisplay.size > 1
        ? "Multiple appointments will be scheduled:"
        : "";
    }

    if (this.state.isAddingAppointment) {
      return "The following appointment will be added:";
    }

    if (this.state.isReschedulingAppointment) {
      return "The appointment will be rescheduled to:";
    }

    if (this.canAddMultipleEvents()) {
      return "The following segments will be offered to the Resident:";
    }

    if (segmentsToDisplay.size > 1) {
      return "Multiple appointments will be scheduled:";
    }

    if (segmentsToDisplay.size === 1) {
      return "The following appointment will be scheduled:";
    }

    return "";
  },

  renderEventTimes() {
    if (Features.isMultipleAppointmentsEnabled() && this.getMeld().get("meld_type") !== "ESTIMATE") {
      const currentSegments = this.getSegments();
      const tempSegments = I.fromJS(this.state.tempStoredSegments);

      const filteredCurrentSegments =
        !this.state.isAddingAppointment && parseQuery(this.props.location).markScheduled
          ? currentSegments.filter((segment) => !segment.getIn(["event", "id"]))
          : currentSegments;

      const allSegments = filteredCurrentSegments.concat(tempSegments);

      if (allSegments.size === 0) {
        return;
      }

      let segmentsToDisplay;
      if (this.state.isAddingAppointment || this.state.isReschedulingAppointment) {
        segmentsToDisplay = this.getSegments().filter((segment) => !segment.getIn(["event", "id"]));
      } else {
        segmentsToDisplay = allSegments;
      }

      const sortedSegments = segmentsToDisplay.sort((a, b) => {
        const aStart = moment(a.getIn(["event", "dtstart"]));
        const bStart = moment(b.getIn(["event", "dtstart"]));
        return aStart.diff(bStart);
      });

      return (
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <h3 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>
            {this.getAppointmentHeaderText(segmentsToDisplay)}
          </h3>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {sortedSegments.map((segment, index) => {
              const event = segment.get("event");
              const dtstart = event.get("dtstart");
              const dtend = event.get("dtend");
              return (
                <li key={index} style={{ marginBottom: "5px" }}>
                  {this.formatDateTime(dtstart, dtend)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  },

  cancel() {
    track(
      VendorAvailabilityDeferred({
        meldId: this.getMeld().get("id"),
        pageName: "vendor_hub>scheduling_calendar>availability",
      })
    );
    this._redirectToMeld();
  },

  renderWarningMessage() {
    const segmentCount = this.getSegmentCount();
    if (segmentCount < this.state.appointmentsRequired) {
      return (
        <p style={{ color: "red", marginBottom: "10px" }}>
          You must offer the resident at least{" "}
          {this.state.appointmentsRequired === 1 ? 2 : this.state.appointmentsRequired} Appointments to choose from.
        </p>
      );
    }
    return null;
  },

  handleNewSegment(newSegment) {
    if (this.canAddMultipleEvents()) {
      this._addSegment(newSegment);
    } else {
      if (Features.isMultipleAppointmentsEnabled() && this.getMeld().get("meld_type") !== "ESTIMATE") {
        const currentSegments = this.getSegments();
        if (currentSegments.size > 0) {
          const lastSegment = currentSegments.last();
          this.setState((prevState) => ({
            tempStoredSegments: [...prevState.tempStoredSegments, lastSegment],
            data: this.setSegments(I.List([newSegment])),
          }));
        } else {
          this.setState({
            data: this.setSegments(I.List([newSegment])),
          });
        }

        this.setState((prevState) => ({
          events: [...(prevState.events || []), newSegment],
        }));
      } else {
        const appointment = this.props.appointment;
        let updatedSegments = this.getSegments()
          .filter((segment) => {
            let apptId = segment.getIn(["scheduled_vendor_appointment", "id"]);
            return appointment && apptId && apptId !== this.props.appointment.get("id");
          })
          .push(newSegment);
        this.setState({ data: this.setSegments(updatedSegments) });
      }
    }
  },

  getCurrentAppointment() {
    return this.props.appointment;
  },

  canAddMultipleEvents() {
    let meld = this.getMeld();
    return (
      MeldUtils.isTenantPresenceRequired(meld) &&
      MeldUtils.hasRegisteredTenant(meld) &&
      !parseQuery(this.props.location).markScheduled
    );
  },

  handleSaveAndAddAnother() {
    const currentSegments = this.getSegments();
    const lastSegment = currentSegments.last();

    this.setState((prevState) => ({
      tempStoredSegments: [...prevState.tempStoredSegments, lastSegment],
      data: this.setSegments(currentSegments.pop()),
    }));
  },

  save() {
    if (Features.isMultipleAppointmentsEnabled() && this.getMeld().get("meld_type") !== "ESTIMATE") {
      this.setState({ showSchedulingLoader: true });
    }

    track(VendorMeldScheduled({ meldId: this.getMeld().get("id") }));
    if (this.isValid()) {
      if (this.props.assignment.get("accepted")) {
        let segmentsIdsToKeep = this.getSegmentIdsToKeep();
        let newSegments = this.getNewSegments();

        if (Features.isMultipleAppointmentsEnabled() && this.getMeld().get("meld_type") !== "ESTIMATE") {
          if (this.state.isReschedulingAppointment) {
            track(VendorRescheduledAppointment());

            const updatedSegment = newSegments.find(
              (segment) => segment.scheduled_vendor_appointment.id === parseInt(this.state.reschedulingAppointmentId)
            );
            meldActions
              .rescheduleVendorAppointment(
                updatedSegment.scheduled_vendor_appointment.id,
                {
                  requestedSegment: updatedSegment,
                },
                { cid: this.state.cid }
              )
              .then(() => {
                this._redirectToMeld();
              })
              .catch(() => {
                return null;
              });
          } else if (this.state.isAddingAppointment) {
            track(VendorCreatedAdditionalAppointment());

            const newSegments = this.getSegments().filter((segment) => !segment.getIn(["event", "id"]));
            meldActions
              .addNewVendorAppointment(
                this.props.assignment.get("meld").get("id"),
                {
                  new_segments: newSegments.toJS(),
                },
                { cid: this.state.cid }
              )
              .then(() => {
                this._redirectToMeld();
              })
              .catch(() => {
                this.setState({ showSchedulingLoader: false });
                return null;
              });
          } else if (this.state.tempStoredSegments.length > 0) {
            const allSegments = this.getNewSegments().concat(this.state.tempStoredSegments);

            if (allSegments.length > 1) {
              track(VendorCreatedMultipleAppointmentsOnInitialSchedule(allSegments.length));
            }

            AssignmentRequestActions.updateSegments(
              this.props.assignment.get("id"),
              {
                segments_to_keep: [],
                new_segments: [],
                multiple_segments_to_book: allSegments,
                mark_scheduled: true,
                appointments_required: null,
              },
              { cid: this.state.cid }
            );
          } else {
            if (this.state.appointmentsRequired > 1) {
              track(VendorSentAvailabilityWithRequiredAmountOfAppointments(this.state.appointmentsRequired));
            }
            AssignmentRequestActions.updateSegments(
              this.props.assignment.get("id"),
              {
                segments_to_keep: segmentsIdsToKeep,
                new_segments: newSegments,
                mark_scheduled: !!parseQuery(this.props.location).markScheduled,
                appointments_required: this.state.appointmentsRequired,
              },
              { cid: this.state.cid }
            );
          }
        } else {
          AssignmentRequestActions.updateSegments(
            this.props.assignment.get("id"),
            {
              segments_to_keep: segmentsIdsToKeep,
              new_segments: newSegments,
              mark_scheduled: !!parseQuery(this.props.location).markScheduled,
              appointments_required: this.state.appointmentsRequired,
            },
            { cid: this.state.cid }
          );
        }
      } else {
        AssignmentRequestActions.accept(this.props.assignment.get("id"), this.state.data.toJS(), {
          cid: this.state.cid,
        });
      }
    }
  },

  handleAppointmentRequestScheduled() {
    track(
      VendorAvailabilitySubmitted({
        meldId: this.getMeld().get("id"),
        pageName: "vendor_hub>scheduling_calendar>availability",
      })
    );
    this._redirectToMeld();
  },

  _redirectToMeld() {
    let meld = this.props.assignment.get("meld");
    HistoryUtils.push(this.props.history, `/meld/${meld.get("id")}/summary/`);
  },

  componentWillUpdate(nextProps, nextState) {
    if (this.didRequestSucceed(nextState)) {
      // TODO Hack - Alt will throw an invariant exception since the route we are requesting will invoke
      // `vendorMeldFileStore.fetchFilesForMeld`, which will dispatch another flux action (which isn't allowed)
      setTimeout(() => {
        this._redirectToMeld();
      }, 100);
    }
  },
});

let AcceptMeldAvailabilityContainer = withRouter(
  createReactClass({
    mixins: [Status],
    loadingStore: AssignmentRequestStore,

    getInitialState() {
      return {
        loadingAppointment: true,
      };
    },

    render() {
      if (AssignmentRequestStore.isLoading() || this.props.apptRequestLoading || this.state.loadingAppointment) {
        return <LargeLoader />;
      }

      let assignmentId = this.props.match.params.id;
      let appt = this.state.appointment;

      return (
        <AltContainer
          stores={{
            assignment() {
              return {
                value: AssignmentRequestStore.getOne(assignmentId),
                store: AssignmentRequestStore,
              };
            },
          }}
        >
          <AcceptMeldAvailability {...this.props} appointment={appt} />
        </AltContainer>
      );
    },

    componentWillMount() {
      const { id } = this.props.match.params;
      AssignmentRequestStore.fetchOne(id);

      this.fetchAssignment(this.props);
    },

    fetchAssignment(props) {
      const { id } = this.props.match.params;
      vendorAppointmentApi
        .fetchByAssignment(id)
        .then((res) => {
          this.setState({
            appointment: I.fromJS(res.data),
            loadingAppointment: false,
          });
        })
        .catch((error) => ErrorHandler.handleAxiosError(error));
    },

    componentWillUpdate(nextProps) {
      if (nextProps.match.params.id !== this.props.match.params.id) {
        this.fetchAssignment(nextProps);
      }
    },

    componentWillReceiveProps(nextProps) {
      AssignmentRequestStore.fetchOne(nextProps.match.params.id);
    },
  })
);

let propsMapper = compose(
  mapProps((props) => {
    return {
      ...props,
      appointmentRequest: props.data,
      apptRequestLoading: props.loading,
    };
  })
);

AcceptMeldAvailabilityContainer = propsMapper(AcceptMeldAvailabilityContainer);
AcceptMeldAvailabilityContainer = withAppointmentRequestFromAssignment(
  AcceptMeldAvailabilityContainer,
  (props) => props.match.params.id
);

export default withRouter(AcceptMeldAvailabilityContainer);
